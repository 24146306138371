import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Input, Row, Select, message, Table, Modal, Form } from 'antd';
import axios from 'axios';
import * as XLSX from 'xlsx';
import Main from '../components/layout/Main';
import { MdOutlineBlock } from "react-icons/md";

const { Option } = Select;

const AddWallet = () => {
  const [chains, setChains] = useState([]);
  const [totp, setTotp] = useState('');
  const [readWalletAddress, setReadWalletAddress] = useState([]);
  const [wallet, setWallet] = useState([]);
  const [chainSelect, setChainSelect] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const GetChain = () => {
    const jwt = localStorage.getItem('jwt');
    const postData = { jwt: jwt };
    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API + 'get_chains',
      data: postData,
    })
      .then(function (response) {
        const data = response.data.Success;
        setChains(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetChain();
  }, []);

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileExtension = file.name.split('.').pop().toLowerCase();
    const validExtensions = ['xlsx'];

    if (!validExtensions.includes(fileExtension) || file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
      message.error('Invalid file format. Please upload an .xlsx file.');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const walletAddresses = [];

      for (const cell in sheet) {
        if (cell[0] === '!') continue;
        const cellValue = sheet[cell].v;
        walletAddresses.push(cellValue);
      }

      setReadWalletAddress(walletAddresses);
    };

    reader.readAsArrayBuffer(file);
  };

  const onChange = (value) => {
    setChainSelect(value);
  };

  const totpHandle = (e) => {
    setTotp(e.target.value);
  };

  const showModal = () => {
    if (!chainSelect || readWalletAddress.length === 0) {
      message.error('Please fill all the required fields.');
      return;
    }
    setIsModalVisible(true);
  };

  const handleOk = () => {
    if (!totp) {
      message.error('Please enter the TOTP.');
      return;
    }

    const jwt = localStorage.getItem('jwt');
    const postData = {
      jwt: jwt,
      totp: totp,
      chain: chainSelect,
      wallet_address: readWalletAddress,
    };

    axios({
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      url: process.env.REACT_APP_API + 'add_wallets',
      data: postData,
    })
      .then(function (response) {
        const data = response.data.Success;
        setWallet(data);
        message.success(response.data.Success);
        setIsModalVisible(false);
      })
      .catch((err) => {
        message.error(err.response.data.Error);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [walletData, setWalletData] = useState([]);

  const GetWalletData = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "get_wallets_history",
      data: postData
    })
      .then(function (response) {
        const data = response.data.Success;
        setWalletData(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetWalletData();
  }, []);

  const WalletColumn = [
    {
      title: 'DATE',
      dataIndex: 'Date_Created',
      key: 'Date_Created',
    },
    {
      title: 'PROJECT ID',
      dataIndex: 'Project_ID',
      key: 'Project_ID',
    },
    {
      title: 'CHAIN',
      dataIndex: 'Chain',
      key: 'Chain',
    },
    {
      title: 'WALLETS',
      dataIndex: 'Wallets',
      key: 'Wallets',
    },
  ];

  const walletSource = [];
  if (walletData) {
    for (let i = 0; i < walletData.length; ++i) {
      walletSource.push({
        key: i,
        Date_Created: walletData[i].Date_Created || <MdOutlineBlock className='red' />,
        Project_ID: walletData[i].Project_ID || <MdOutlineBlock className='red' />,
        Chain: walletData[i].Chain || <MdOutlineBlock className='red' />,
        Wallets: walletData[i].Wallets || <MdOutlineBlock className='red' />,
      });
    }
  }

  return (
    <>
      <Main>
        <Row justify="center">
          <Col xs={24} md={18}>
            <Card>
              <Form layout="vertical">
                <Row gutter={[24, 24]} align={'middle'} justify={'center'}>
                  <Col xs={24} md={8}>
                    <Form.Item label="Chain" required>
                      <Select style={{ width: '100%' }} onChange={onChange} required>
                        {chains.map((chain) => (
                          <Option key={chain} value={chain}>
                            {chain}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={8}>
                    <Form.Item label="Upload Wallet Address" required>
                      <input type="file" accept=".xlsx" onChange={handleFileUpload} className="border-upload w-full" required />
                    </Form.Item>
                  </Col>
                  <Col xs={24} md={6}>
                    <Button className="theme text-white w-full text-15px" onClick={showModal}>
                      Submit
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Col>
        </Row>
        <Card className="mt">
          <div className='table-responsive'>
            <Table size="small" dataSource={walletSource.reverse()} columns={WalletColumn} />
          </div>
        </Card>
        <Modal title="Enter TOTP" visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <Form layout="vertical">
            <Form.Item label="TOTP" required>
              <Input placeholder="Totp here..." onChange={totpHandle} required />
            </Form.Item>
          </Form>
        </Modal>
      </Main>
    </>
  );
};

export default AddWallet;
