import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  Table,
  Typography,
  Button,
  Modal,
  Switch,
  Input,
  message,
  Checkbox
} from "antd";
import axios from "axios";
import Main from "../components/layout/Main";

const { Paragraph } = Typography;

function CreateApi() {
  const [modalVisible, setModalVisible] = useState(false);
  const [otpModalVisible, setOtpModalVisible] = useState(false);
  const [otp, setOtp] = useState("");
  const [createApiData, setCreateApiData] = useState([]);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [currentRecordPermissions, setCurrentRecordPermissions] = useState({});
  const [ApiName, setApiName] = useState("");
  const [totpValue, setTotpValue] = useState("");
  const [capi, setCapi] = useState([]);
  const [visible, setVisible] = useState(false);
  const [TwoFa, setTwoFa] = useState('');
  const [AddIps, setAddIps] = useState(false);
  const [adIp, setadIp] = useState('');
  const [visibleOtp, setVisibleOtp] = useState(false);
  const [otpApiName, setotpApiName] = useState('');
  const [visibleIPs, setVisibleIPs] = useState(false);
  const [RemoveIpsOtp, setRemoveIPsOtp] = useState(false)

  const handlePermissionChange = (permissionKey, checked) => {
    setCurrentRecordPermissions(prevPermissions => ({
      ...prevPermissions,
      [permissionKey]: checked
    }));
  };

  const handlePermissionClick = record => {
    setCurrentRecord(record); // Set the current record
    setCurrentRecordPermissions(record.Permissions || {});
    setModalVisible(true);
  };

  const handleOtpSubmit = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = {
      jwt: jwt,
      totp: otp,
      api_name: currentRecord.API_Name,
      permission_lst: [{ ...currentRecordPermissions }] // Send the updated permission data
    };

    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "change_permissions",
      data: postData
    })
      .then(function (response) {
        console.log(response.data);
        // If the API call is successful, update the table data
        TableData();
        message.success("Permissions updated successfully");
      })
      .catch(err => {
        console.log(err);
        message.error(err.response.data.Error); // Handle error if needed
      })
      .finally(() => {
        setOtp("");
        setOtpModalVisible(false);
      });
  };

  const columns = [
    {
      title: "DATE",
      dataIndex: "Date_Created",
      key: "Date_Created"
    },
    {
      title: "API KEY",
      dataIndex: "API_Key",
      key: "API_Key",
      width: "32%"
    },
    {
      title: "API NAME",
      dataIndex: "API_Name",
      key: "API_Name"
    },
    {
      title: "TODAYS REQUEST",
      key: "Todays_Requests",
      dataIndex: "Todays_Requests"
    },
    {
      title: "TOTAL REQUEST",
      key: "Total_Requests",
      dataIndex: "Total_Requests"
    },
    {
      title: "IPs",
      key: "IPs",
      dataIndex: "IPs",
      render: (text, record) => (
        <Button className="table-btn" onClick={() => showModalIPs(record)}>IPs</Button>
      )
    },
    {
      title: "WEBHOOK",
      key: "Webhook",
      dataIndex: "Webhook",
      render: (text, record) => (
        <Button className="table-btn" onClick={() => showWebhookModal(record)}>
          Webhook
        </Button>
      )
    },
    {
      title: "PERMISSION",
      key: "permission",
      dataIndex: "permission",
      render: (text, record) => (
        <Button className="table-btn" onClick={() => handlePermissionClick(record)}>Permission</Button>
      )
    },
    {
      title: "DELETE",
      key: "delete",
      dataIndex: "delete",
      render: (text, record) => (
        <Button className="table-btn"
          onClick={() => showModalOtp(record)}
        >
          Delete
        </Button>
      )
    }
  ];

  useEffect(() => {
    TableData();
  }, []);

  const [selectedValues, setSelectedValues] = useState([]);

  const onChange = (value) => {
    setSelectedValues(prevSelectedValues => {
      const index = prevSelectedValues.indexOf(value);
      if (index === -1) {
        // Value not present in the array, add it
        return [...prevSelectedValues, value];
      } else {
        // Value already present in the array, remove it
        return prevSelectedValues.filter(item => item !== value);
      }
    });
  };

  useEffect(() => {
    console.log(selectedValues); // Print the selected IP addresses whenever selectedValues changes
  }, [selectedValues]);

  console.log(selectedValues)

  const TableData = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "get_apis",
      data: postData
    })
      .then(function (response) {
        const data = response.data.Success.map((item, index) => ({
          ...item,
          key: index,
          Permissions: item.Permissions || {}, // Set initial permissions for each record
          IPs: item.IPs.map((ip, ipIndex) =>
            <>
              <div className="text-start flex justify-between items-center text-20px" key={ipIndex}>
                <div>
                  {/* {ipIndex + 1}
                :  */}
                  <Checkbox onChange={() => onChange(ip)}>{ip}</Checkbox>
                </div>
                {/* <div>
                  <IoIosRemoveCircle className="text-red" />
                </div> */}
              </div>

            </>
          ) // Convert array of IPs to string separated by comma
        }));
        setCreateApiData(data);
      })
      .catch((err) => {
        console.log(err.response.data.Error);
        message.error(err.response.data.Error);
      });
  };


  const otpValue = (e) => {
    setTotpValue(e.target.value);
  };

  const apiname = (e) => {
    setApiName(e.target.value);
  };

  const CreateAPi = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt, totp: totpValue, api_name: ApiName };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "genrate_api_key",
      data: postData
    })
      .then(function (response) {
        message.success(response.data.Success);
        setCapi(response.data);
        TableData();
        showModalKey();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    // setModalVisible(false); // Close the permission modal
    setOtpModalVisible(true); // Open OTP modal
  };

  const handleCancel = () => {
    setModalVisible(false);
  };

  const Delete = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt, api_name: otpApiName, totp: TwoFa };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "delete_apis",
      data: postData
    })
      .then(function (response) {
        const data = response.data
        console.log(data)
        TableData();
        message.success(response.data.Success);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const showModalOtp = (record) => {
    setVisibleOtp(true);
  };

  const handleOkOtp = () => {
    Delete();
    setVisibleOtp(false);
  };

  const handleCancelOtp = () => {
    setVisibleOtp(false);
  };

  const showModalIPs = (record) => {
    setotpApiName(record.API_Name);
    setVisibleIPs(true);
  };

  const handleOkIPs = () => {
    setVisibleIPs(false);
  };

  const handleCancelIPs = () => {
    setVisibleIPs(false);
  };

  const columnsIps = [
    {
      title: <div className="font-bold text-white">ALL IPS &darr;</div>,
      dataIndex: "IPs",
      key: "IPs"
    },
  ];

  const showModalAddIPs = (record) => {
    setAddIps(true);
  };

  const handleOkAddIPs = () => {
    AddIPS();
    setAddIps(false);
  };

  const handleCancelAddIPs = () => {
    setAddIps(false);
  };

  const AddIPS = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt, api_name: otpApiName, totp: TwoFa, ip: adIp };
    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "add_ip",
      data: postData
    })
      .then(function (response) {
        const data = response.data
        console.log(data)
        TableData();
        message.success(response.data.Success);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const RemoveIPS = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt, api_name: otpApiName, totp: TwoFa, ip: selectedValues };
    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "remove_ip",
      data: postData
    })
      .then(function (response) {
        const data = response.data
        console.log(data)
        TableData();
        message.success(response.data.Success);
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const showModalRemoveIPsOtp = (record) => {
    setRemoveIPsOtp(true);
  };

  const handleOkRemoveIPsOtp = () => {
    RemoveIPS();
    setRemoveIPsOtp(false);
  };

  const handleCancelRemoveIPsOtp = () => {
    setRemoveIPsOtp(false);
  };

  const [visibleKey, setVisibleKey] = useState(false);

  const showModalKey = () => {
    setVisibleKey(true);
  };

  const handleOkKey = () => {
    // Add your OK handling logic here
    console.log('OK clicked');
    setVisibleKey(false);
  };

  const handleCancelKey = () => {
    // Add your Cancel handling logic here
    console.log('Cancel clicked');
    setVisibleKey(false);
  };


  /////////Add webhook modal ////////
  const [webhookModal, setwebhookModal] = useState(false);
  const [webUrl, setwebUrl] = useState('');
  const [getUrl, setgetUrl] = useState([]);

  const showWebhookModal = () => {
    setwebhookModal(true);
  };
  const handleOkWebhook = () => {
    setwebhookModal(false);
  };
  const handleCancelWebhook = () => {
    setwebhookModal(false);
  };

  const webhookColumn = [
    {
      title: <div className="font-bold text-white">WEBHOOK URL &darr;</div>,
      dataIndex: "url",
      key: "url"
    },
  ];

  const webhook = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt, url: webUrl };
    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "add_webhook",
      data: postData
    })
      .then(function (response) {
        console.log(response.data)
        message.success(response.data.Success);
        TableData();
        Profile();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const Profile = () => {
    const jwt = localStorage.getItem('jwt');
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "view_profile",
      data: postData,
    })
      .then(function (response) {
        const data = response.data.Success.Webhook_Url;
        console.log(data);
        setgetUrl([{ key: 1, url: data }])
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Profile();
  }, []);

  return (
    <>
      <Main>

        <Modal
          title="ADD WEBHOOK"
          open={webhookModal}
          onOk={handleOkWebhook}
          onCancel={handleCancelWebhook}
        >
          <Input
            placeholder="Enter Webhook url..."
            onChange={(e) => setwebUrl(e.target.value)}
          />
          <div className="flex mt-5px justify-center">
            <Button
              className="theme text-white"
              onClick={webhook}>
              Add
            </Button>
          </div>

          <div className="mt-5px">
            <Table
              size="small"
              columns={webhookColumn}
              dataSource={getUrl}
              pagination={false}
              className="ant-border-space"
            />
          </div>
        </Modal>

        <Modal
          title={null}
          footer={null}
          visible={visibleKey}
          onOk={handleOkKey}
          onCancel={handleCancelKey}
        >
          <div>
            <Row>
              <Col xs={3}>
                <p>Api Key :</p>
              </Col>
              <Col xs={20}>
                <Paragraph copyable className="text-15px">
                  {capi.Key ? capi.Key : ""}
                </Paragraph>
              </Col>
            </Row>
          </div>
          <div>
            <Row>
              <Col xs={4}>
                <p>Secret Key :</p>
              </Col>
              <Col xs={20}>
                <Paragraph copyable className="text-15px">
                  {capi.Secret ? capi.Secret : ""}
                </Paragraph>
              </Col>
            </Row>
          </div>
          <p className="text-red">
            Note: Please be informed that your Secret Key will only be visible
            once and will not be displayed again thereafter.
          </p>
        </Modal>

        <Modal
          title={null}
          visible={modalVisible}
          onCancel={() => setModalVisible(false)}
          onOk={handleOk}
        >
          <div className="">
            {Object.entries(currentRecordPermissions).map(([key, value]) => (
              <div key={key}>
                <Row justify={'center'} align={'middle'}>
                  <Col xs={12} md={12}>
                    <span>{key.replace(/_/g, " ")}</span>
                  </Col>
                  <Col xs={12} md={6} className="flex justify-center mt-5px">
                    <Switch checked={value} onChange={(checked) => handlePermissionChange(key, checked)} />
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Modal>

        <Modal
          title="Enter OTP"
          visible={otpModalVisible}
          onOk={handleOtpSubmit}
          onCancel={() => setOtpModalVisible(false)}
        >
          <Input value={otp} onChange={(e) => setOtp(e.target.value)} />
        </Modal>

        <Modal
          title="Enter OTP to Delete"
          visible={visibleOtp}
          onOk={handleOkOtp}
          onCancel={handleCancelOtp}
        >
          <Input onChange={(e) => setTwoFa(e.target.value)} />

        </Modal>

        <Modal
          title="Add IPs"
          visible={visibleIPs}
          onOk={handleOkIPs}
          onCancel={handleCancelIPs}
        >
          <Input placeholder="Enter IPs here..." onChange={(e) => setadIp(e.target.value)} />
          <div className="flex mt-5px justify-center">
            <Button className="theme text-white" onClick={showModalAddIPs}>Add</Button>
          </div>

          <div className="mt-5px">
            <Table
              columns={columnsIps}
              dataSource={createApiData}
              pagination={false}
              className="ant-border-space"
            />
            <div className="mt-5px flex justify-center">
              <Button
                className="theme text-white"
                onClick={() => showModalRemoveIPsOtp()}
              >
                Remove IPs
              </Button>
            </div>
          </div>
        </Modal>

        <Modal
          title="Enter OTP to Delete"
          visible={AddIps}
          onOk={handleOkAddIPs}
          onCancel={handleCancelAddIPs}
        >
          <Input onChange={(e) => setTwoFa(e.target.value)} />

        </Modal>

        <Modal
          title="Enter OTP to Remove IPS"
          visible={RemoveIpsOtp}
          onOk={handleOkRemoveIPsOtp}
          onCancel={handleCancelRemoveIPsOtp}
        >
          <Input onChange={(e) => setTwoFa(e.target.value)} />
        </Modal>

        <div className="tabled">
          <Card bordered={false} className="criclebox card-info-2 h-full">
            <div className="theme h-full col-content">
              <Row className="gap items-center" justify="center">
                <Col xs={24} md={6} >
                  <Input
                    className="header-search"
                    placeholder="API NAME HERE..."
                    onChange={apiname}
                  />
                </Col>
                <Col xs={24} md={6}>
                  <Input
                    className="header-search"
                    placeholder="TOTP HERE..."
                    onChange={otpValue}
                  />
                </Col>
                <Col xs={24} md={2}>
                  <Button className="uppercase w-full" onClick={CreateAPi}>Create</Button>
                </Col>
              </Row>
            </div>
          </Card>
          <Row gutter={[24, 24]} className="mt">
            <Col xs={24}>
              <Card bordered={false} className="criclebox tablespace mb-24" title="CREATED API'S">
                <div className="table-responsive">
                  <Table
                    size="small"
                    columns={columns}
                    dataSource={createApiData}
                    pagination={false}
                    className="ant-border-space"
                  />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
}

export default CreateApi;
