import { useLocation, useNavigate } from "react-router-dom";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ProtectedRoute from "./pages/ProtectedRoutes";
import { Routes, Route, } from 'react-router-dom';
import Home from './pages/Home';
import CreateApi from './pages/CreateApi';
import Profile from './pages/Profile';
import AddWallet from './pages/AddWallet';
import Support from './pages/Support';
import LoginLogs from "./pages/LoginLogs";
import Wallet from "./pages/Wallet";
import Transaction from "./pages/Transaction";

function App() {
  const navigate = useNavigate();
  const location = useLocation();

  function NavigateToHome() {
    const navigate = useNavigate();
    navigate('/');
    return null;
  }

  return (
    <div className="App">
      <Routes>
        <Route index element={<SignIn />} />
        <Route exact path="/signup" element={<SignUp />} />

        <Route
          exact
          path="/dashboard"
          element={<ProtectedRoute Component={Home} />}
        />
        <Route
          exact
          path="/createApi"
          element={<ProtectedRoute Component={CreateApi} />}
        />
        <Route
          exact
          path="/addWallet"
          element={<ProtectedRoute Component={AddWallet} />}
        />
        <Route
          exact
          path="/support"
          element={<ProtectedRoute Component={Support} />}
        />
        <Route
          exact
          path="/Logs"
          element={<ProtectedRoute Component={LoginLogs} />}
        />
        <Route
          exact
          path="/wallet"
          element={<ProtectedRoute Component={Wallet} />}
        />
        <Route
          exact
          path="/transaction"
          element={<ProtectedRoute Component={Transaction} />}
        />
        <Route
          exact
          path="/profile"
          element={<ProtectedRoute Component={Profile} />}
        />

      </Routes>
    </div>
  );
}

export default App;
