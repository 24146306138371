import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Row,
  Table,
  Typography,
} from "antd";
import axios from "axios";
import Main from '../components/layout/Main';
import btc from '../assets/Coins/2.png';
import eth from '../assets/Coins/3.png';
import trx from '../assets/Coins/7.png';
import bnb from '../assets/Coins/1.png';
import erc from '../assets/Coins/6.png';
import trc from '../assets/Coins/5.png';
import bep from '../assets/Coins/4.png';

function Home() {

  const [walletBalance, setWalletBalance] = useState([]);
  const [AllPayment, setAllPayment] = useState([]);
  const [Wallet, setWallet] = useState([]);

  const { Title } = Typography;

  const GetWalletBalance = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "get_wallet_balances",
      data: postData
    })
      .then(function (response) {
        const data = response.data.Success;
        console.log(data);
        setWalletBalance(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetWalletBalance();
  }, [])

  const count = [
    {
      symbol: "BTC",
      value: <>
        <div className="text-17px">{walletBalance.BTC ? walletBalance.BTC.toFixed(3) : 0} BTC </div>
        <div className="text-17px">$ {walletBalance.BTC_USDT ? walletBalance.BTC_USDT.toFixed(3) : 0}</div>
      </>,
      icon: btc,
    },
    {
      symbol: "ETH",
      value: <>
        <div className="text-17px">{walletBalance.ETH ? walletBalance.ETH.toFixed(3) : 0} ETH </div>
        <div className="text-17px">$ {walletBalance.ETH_USDT ? walletBalance.ETH_USDT.toFixed(3) : 0}</div>
      </>,
      icon: eth,
    },
    {
      symbol: "TRX",
      value: <>
        <div className="text-17px">{walletBalance.TRX ? walletBalance.TRX.toFixed(3) : 0} TRX </div>
        <div className="text-17px">$ {walletBalance.TRX_USDT ? walletBalance.TRX_USDT.toFixed(3) : 0}</div>
      </>,
      // persent: "-20%",
      icon: trx,
    },
    {
      symbol: "BNB",
      value: <>
        <div className="text-17px">{walletBalance.BNB ? walletBalance.BNB.toFixed(3) : 0} BNB </div>
        <div className="text-17px">$ {walletBalance.BNB_USDT ? walletBalance.BNB_USDT.toFixed(3) : 0}</div>
      </>,
      icon: bnb,
    },
    {
      symbol: "ERC20 USDT",
      value: <>
        <div className="text-17px">{walletBalance.ERC20_USDT ? walletBalance.ERC20_USDT.toFixed(3) : 0} ERC20 </div>
        <div className="text-17px">$ {walletBalance.ERC20_USDT ? walletBalance.ERC20_USDT.toFixed(3) : 0}</div>
      </>,
      icon: erc,
    },
    {
      symbol: "TRC20 USDT",
      value: <>
        <div className="text-17px">{walletBalance.TRC20_USDT ? walletBalance.TRC20_USDT.toFixed(3) : 0} TRC20 </div>
        <div className="text-17px">$ {walletBalance.TRC20_USDT ? walletBalance.TRC20_USDT.toFixed(3) : 0}</div>
      </>,
      icon: trc,
    },
    {
      symbol: "BEP20 USDT",
      value: <>
        <div className="text-17px">{walletBalance.BSC20_USDT ? walletBalance.BSC20_USDT.toFixed(3) : 0} BEP20 </div>
        <div className="text-17px">$ {walletBalance.BSC20_USDT ? walletBalance.BSC20_USDT.toFixed(3) : 0}</div>
      </>,
      icon: bep,
    },
  ];

  const dataSource = [];
  if (AllPayment) {

    const completedPayments = AllPayment.filter(payment => payment.Status === "COMPLETED");

    const sortedPayments = completedPayments.sort((a, b) => new Date(b.Date_Created) - new Date(a.Date_Created));

    const latestPayments = sortedPayments.reverse().slice(0, 5);

    // Map the latest payments to dataSource
    for (let i = 0; i < latestPayments.length; ++i) {
      dataSource.push({
        key: i,
        Date_Created: latestPayments[i].Date_Created,
        Project_ID: latestPayments[i].Project_ID,
        Transaction_ID: latestPayments[i].Transaction_ID,
        Username: latestPayments[i].Username,
        Chain: latestPayments[i].Chain.replace('_', " "),
        Transaction_Hash: latestPayments[i].Transaction_Hash,
        Wallet_Address: latestPayments[i].Wallet_Address,
        Status: latestPayments[i].Status,
        Ip: latestPayments[i].Ip,
      });
    }

    // Log dataSource for debugging
    console.log('Data Source:', dataSource);
  }

  const columns = [
    {
      title: 'DATE',
      dataIndex: 'Date_Created',
      key: 'Date_Created',
    },
    {
      title: 'PROJECT ID',
      dataIndex: 'Project_ID',
      key: 'Project_ID',
    },
    {
      title: 'TRANSACTION ID',
      dataIndex: 'Transaction_ID',
      key: 'Transaction_ID',
    },
    {
      title: 'USERNAME',
      dataIndex: 'Username',
      key: 'Username',
    },
    {
      title: 'CHAIN',
      dataIndex: 'Chain',
      key: 'Chain',
    },
    {
      title: 'TRANSACTION HASH',
      dataIndex: 'Transaction_Hash',
      key: 'Transaction_Hash',
    },
    {
      title: 'WALLET ADDRESS',
      dataIndex: 'Wallet_Address',
      key: 'Wallet_Address',
    },
    {
      title: 'IP',
      dataIndex: 'Ip',
      key: 'Ip',
    },
    {
      title: 'STATUS',
      dataIndex: 'Status',
      key: 'Status',
    },
  ];

  const GetAllPayment = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "get_all_payments",
      data: postData
    })
      .then(function (response) {
        const data = response.data.Success;
        console.log(data);
        setAllPayment(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };


  const GetWallets = () => {
    const jwt = localStorage.getItem("jwt");
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "get_wallets",
      data: postData
    })
      .then(function (response) {
        const data = response.data.Success;
        setWallet(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    GetAllPayment();
    GetWallets();
  }, []);

  const Walletcolumns = [
    {
      title: 'CHAINS',
      dataIndex: 'Chain',
      key: 'Chain',
    },
    {
      title: 'TOTAL WALLETS',
      dataIndex: 'Total_Wallets',
      key: 'Total_Wallets',
    },
    {
      title: 'ALLOTED',
      dataIndex: 'Alloted',
      key: 'Alloted',
    },
    {
      title: 'NOT ALLOTED',
      dataIndex: 'Not_Alloted',
      key: 'Not_Alloted',
    },
  ];

  const WalletdataSource = [];
  if (Wallet.length > 0) {
    for (let i = 0; i < Wallet.length; ++i) {
      WalletdataSource.push({
        key: i,
        Chain: Wallet[i].Chain.replace('_', " "),
        Total_Wallets: Wallet[i].Total_Wallets,
        Alloted: Wallet[i].Alloted,
        Not_Alloted: Wallet[i].Not_Alloted,
      })
    }
  }

  return (
    <>
      <Main>
        <div className="layout-content">
          <Row className="rowgap-vbox" gutter={[24, 0]}>
            {count.map((c, index) => (
              <Col
                key={index}
                xs={24}
                md={6}
                className="mb-24"
              >
                <Card bordered={false} className="criclebox ">
                  <div className="number">
                    <Row align="middle" gutter={[24, 0]}>
                      <Col xs={18}>
                        <span className="theme-text">{c.symbol}</span>
                        <Title level={3}>
                          {c.value} <small className={c.bnb}>{c.persent}</small>
                        </Title>
                      </Col>
                      <Col xs={6}>
                        <div className="icon-box flex items-center justify-center"> <img src={c.icon} alt="" /> </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            ))}
          </Row>

          <Row gutter={[24, 0]}>
            <Col xs={24} className="mb-24">
              <Card
                title={<div className="text-20px theme-text font-semibold">PAYMENTS</div>}
                // bordered={false} 
                className="criclebox h-full"
              >
                <div className="table-responsive">
                  <Table size="small" dataSource={dataSource} columns={columns} />
                </div>
              </Card>
            </Col>

            <Col xs={24} className="mb-24">
              <Card
                title={<div className="text-20px theme-text font-semibold">WALLETS</div>}
                // bordered={false} 
                className="criclebox card-info-2 h-full"
              >
                <div className="table-responsive">
                  <Table size="small" dataSource={WalletdataSource.reverse()} columns={Walletcolumns} />
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Main>
    </>
  );
}

export default Home;
