import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

const ProtectedRoute = (props) => {
  const { Component } = props;
  let login = localStorage.getItem('jwt');

  const navigate = useNavigate();
  useEffect(() => {
    let login = localStorage.getItem('jwt');
    if (!login) {
      navigate('/');
    }
  })
  return (
    <>
      {login ? <Component /> : navigate('/')}
    </>
  )
}

export default ProtectedRoute;