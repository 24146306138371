import React, { useEffect, useState } from 'react';
import { Button, Card, Form, Input, Modal, Table, message } from 'antd';
import axios from 'axios';
import Main from '../components/layout/Main';

const { TextArea } = Input;

const Support = () => {

    const [visibleOtp, setVisibleOtp] = useState(false);
    const [Tickets, setTickets] = useState([]);

    const showModal = () => {
        setVisibleOtp(true);
    };

    const handleOk = () => {
        // setModalVisible(false); // Close the permission modal
        setVisibleOtp(false); // Open OTP modal
    };

    const handleCancel = () => {
        setVisibleOtp(false);
    };

    const dataSource = [];
    if (Tickets) {
        for (let i = 0; i < Tickets.length; ++i) {
            dataSource.push({
                key: i,
                Date: Tickets[i].Date,
                Ticket_ID: Tickets[i].Ticket_ID,
                Subject: Tickets[i].Subject,
                Message: Tickets[i].Message,
                Status: Tickets[i].Status,
            })
        }
    }

    const columns = [
        {
            title: 'DATE',
            dataIndex: 'Date',
            key: 'Date',
        },
        {
            title: 'TICKET ID',
            dataIndex: 'Ticket_ID',
            key: 'Ticket_ID',
        },
        {
            title: 'SUBJECT',
            dataIndex: 'Subject',
            key: 'Subject',
        },
        {
            title: 'MESSAGE',
            dataIndex: 'Message',
            key: 'Message',
        },
        {
            title: 'STATUS',
            dataIndex: 'Status',
            key: 'Status',
        },
    ];

    const onFinish = (values) => {
        console.log('Success:', values);
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt, ...values };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "raise_ticket",
            data: postData
        })
            .then(function (response) {
                const data = response.data.Success;
                console.log(data);
                GetSupport();
                setVisibleOtp(false);
                message.success(response.data.Success)
            })
            .catch((err) => {
                console.log(err);
                message.error(err.response.data.Error);
            });
    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const GetSupport = () => {
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "get_tickets",
            data: postData
        })
            .then(function (response) {
                const data = response.data.Success;
                console.log(data)
                setTickets(data)
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        GetSupport();
    }, []);
    return (
        <>
            <Main>

                <Modal
                    title="Enter OTP to Delete"
                    visible={visibleOtp}
                    onOk={handleOk}
                    onCancel={handleCancel}
                    header={null}
                    footer={null}
                >
                    <Form
                        name="basic"
                        labelCol={{
                            span: 4,
                        }}
                        wrapperCol={{
                            span: 20,
                        }}
                        style={{
                            maxWidth: 600,
                        }}
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >
                        <Form.Item
                            label="Subject"
                            name="subject"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Subject!',
                                },
                            ]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label="Message"
                            name="message"
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your Message!',
                                },
                            ]}
                        >
                            <TextArea autoSize={{ minRows: 3, maxRows: 5 }}
                            />
                        </Form.Item>

                        <Form.Item
                            wrapperCol={{
                                offset: 10,
                                span: 24,
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Modal>

                <Card>
                    <div className='flex justify-center'>
                        <Button className='theme text-white' onClick={(record) => showModal(record)}
                        >
                            Submit Support
                        </Button>
                    </div>

                    <div className='table-responsive mt'>
                        <Table
                            size='small'
                            dataSource={dataSource}
                            columns={columns}
                        />
                    </div>
                </Card>
            </Main>
        </>
    )
}

export default Support