import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Descriptions,
  Avatar,
  Input,
  message,
} from "antd";
import profilavatar from "../assets/images/face-6.jpeg";
import axios from "axios";
import QRCode from "qrcode.react";
import Main from "../components/layout/Main";

function Profile() {
  const [profile, setProfile] = useState([]);
  const [Chains, setChains] = useState([]);
  const [totpValue, setTotpValue] = useState('');
  const [qr, setQr] = useState([]);

  const Profile = () => {
    const jwt = localStorage.getItem('jwt');
    const postData = { jwt: jwt };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "view_profile",
      data: postData,
    })
      .then(function (response) {
        const data = response.data.Success;
        console.log(data);
        setProfile(data);
        setChains(data.Chains);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    Profile();
  }, []);

  function totp(e) {
    console.log(e.target.value);
    setTotpValue(e.target.value);
  }

  const EnableTotp = () => {
    const jwt = localStorage.getItem('jwt');
    const postData = { jwt: jwt, totp: totpValue };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "enable_totp",
      data: postData,
    })
      .then(function (response) {
        const data = response;
        console.log(data);
        message.success(response.data.Success)
        Profile();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  const DisableTotp = () => {
    const jwt = localStorage.getItem('jwt');
    const postData = { jwt: jwt, totp: totpValue };
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "disable_totp",
      data: postData,
    })
      .then(function (response) {
        const data = response;
        console.log(data);
        message.success(response.data.Success)
        Profile();
      })
      .catch((err) => {
        console.log(err);
        message.error(err.response.data.Error);
      });
  };

  useEffect(() => {
    if (profile.TOTP === false) {
      console.log('if')

      const jwt = localStorage.getItem('jwt');
      const postData = { jwt: jwt };
      axios({
        method: "POST",
        headers: { "Content-Type": "application/json" },
        url: process.env.REACT_APP_API + "get_totp_uri",
        data: postData,
      })
        .then(function (response) {
          const data = response.data.Success;
          setQr(data);
          console.log(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [profile.TOTP]);

  return (
    <>
      <Main>
        <div className="profile-nav-bg theme"></div>
        <Card
          className="card-profile-head"
          // bodyStyle={{ display: "none" }}
          title={
            <Row justify="space-between" align="middle" gutter={[24, 0]}>
              <Col span={24} md={12} className="col-info">
                <Avatar.Group>
                  <Avatar size={74} shape="square" src={profilavatar} />

                  <div className="avatar-info">
                    <h4 className="font-semibold m-0">{profile.Project_Name} </h4>
                  </div>
                </Avatar.Group>
              </Col>
              {/* <Col
              span={24}
              md={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
              }}
            >
              <Radio.Group defaultValue="a">
                <Radio.Button value="a">OVERVIEW</Radio.Button>
                <Radio.Button value="b">TEAMS</Radio.Button>
                <Radio.Button value="c">PROJECTS</Radio.Button>
              </Radio.Group>
            </Col> */}
            </Row>
          }
        >
          <Row gutter={[24, 0]}>
            <Col span={24} md={8} className="mb-24">
              <Card
                bordered={false}
                title={<h6 className="font-semibold m-0">Profile Information</h6>}
                className="header-solid h-full card-profile-information"
                // extra={<Button type="link">{pencil}</Button>}
                bodyStyle={{ paddingTop: 0, paddingBottom: 16 }}
              >
                <Descriptions>
                  <Descriptions.Item label="Project Name" span={3}>
                    {profile.Project_Name}
                  </Descriptions.Item>
                  <Descriptions.Item label="Project ID" span={3}>
                    {profile.Project_ID}
                  </Descriptions.Item>
                  <Descriptions.Item label="Email" span={3}>
                    {profile.Project_Email}
                  </Descriptions.Item>
                  <Descriptions.Item label="Description" span={3}>
                    {profile.Project_Description}
                  </Descriptions.Item>
                  <Descriptions.Item label="URL" span={3}>
                    {profile.Project_URL}
                  </Descriptions.Item>
                  <Descriptions.Item label="Chains" span={3}>
                    {Chains.join(", ").replace(/_/g, " ")}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Api's" span={3}>
                    {profile.Total_APIs}
                  </Descriptions.Item>
                  <Descriptions.Item label="Total Payments" span={3}>
                    {profile.Total_Payments}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="Social" span={3}>
                  <a href="#pablo" className="mx-5 px-5">
                    {<TwitterOutlined />}
                  </a>
                  <a href="#pablo" className="mx-5 px-5">
                    {<FacebookOutlined style={{ color: "#344e86" }} />}
                  </a>
                  <a href="#pablo" className="mx-5 px-5">
                    {<InstagramOutlined style={{ color: "#e1306c" }} />}
                  </a>
                </Descriptions.Item> */}
                </Descriptions>
              </Card>
            </Col>
            <Col span={24} md={8} className="mb-24">
              <Card bordered={false} className="header-solid h-full">
                <ul className="list settings-list">
                  <li>
                    <h6 className="list-header text-sm text-muted">ACTIVE TOTP</h6>
                  </li>
                  <Row gutter={[16, 16]} align="middle">
                    <Col xs={24}>
                      <Input
                        className="header-search w-full"
                        placeholder="ACTIVATE / DEACTIVATE KEY HERE..."
                        onChange={totp}
                      />
                    </Col>
                    <Col xs={24}>
                      {profile.TOTP === false ? (
                        <Button
                          className="uppercase w-full theme text-white text-15px"
                          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                          onClick={EnableTotp}
                        >ACTIVATE</Button>
                      ) : (
                        <Button
                          className="uppercase w-full theme text-white text-15px"
                          style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}
                          onClick={DisableTotp}
                        >DEACTIVATE</Button>
                      )}
                    </Col>
                  </Row>
                </ul>
              </Card>
            </Col>
            {profile.TOTP === false ?
              <Col span={24} md={8} className="mb-24">
                <Card bordered={false} className="header-solid h-full">
                  <ul className="list settings-list">
                    <li>
                      <h6 className="list-header text-sm text-muted text-center">SCAN FOR TOTP</h6>
                    </li>
                    <Row gutter={[16, 16]} align="middle" justify="'center">
                      <Col xs={24}>
                        <div className="mx-auto flex justify-center">
                          <QRCode
                            value={qr}
                            renderAs="canvas"
                          />
                        </div>
                      </Col>
                    </Row>
                  </ul>
                </Card>
              </Col> : null
            }
          </Row>
        </Card>
      </Main>
    </>
  );
}

export default Profile;
