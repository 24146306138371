import React, { useEffect, useState } from 'react'
import Main from '../components/layout/Main'
import { Card, Table } from 'antd';
import axios from 'axios';
import { MdOutlineBlock } from "react-icons/md";

const LoginLogs = () => {

    const [Loginlogs, setLoginLogs] = useState([]);
    const [logs, setLogs] = useState([]);

    const GetLoginLogs = () => {
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "loginlogs",
            data: postData
        })
            .then(function (response) {
                const data = response.data.Success;
                console.log(data);
                setLoginLogs(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    const GetLogs = () => {
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "get_logs",
            data: postData
        })
            .then(function (response) {
                const data = response.data.Success;
                console.log(data);
                setLogs(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        GetLoginLogs();
        GetLogs();
    }, []);

    const loginLogsColumn = [
        {
            title: 'DATE',
            dataIndex: 'DateTime',
            key: 'DateTime',
        },
        {
            title: 'IP',
            dataIndex: 'IP',
            key: 'IP',
        },
        {
            title: 'DEVICE FAMILY',
            dataIndex: 'Device_Family',
            key: 'Device_Family',
        },
        {
            title: 'DEVICE BRAND',
            dataIndex: 'Device_Brand',
            key: 'Device_Brand',
        },
        {
            title: 'DEVICE MODAL',
            dataIndex: 'Device_Model',
            key: 'Device_Model',
        },
        {
            title: 'DEVICE OS',
            dataIndex: 'Device_OS',
            key: 'Device_OS',
        },
        {
            title: 'OPERATING SYSTEM VERSION',
            dataIndex: 'Operating_System_version',
            key: 'Operating_System_version',
        },
        {
            title: 'BROWSER FAMILY',
            dataIndex: 'Browser_Family',
            key: 'Browser_Family',
        },
        {
            title: 'BROWSER VERSION',
            dataIndex: 'Browser_Version',
            key: 'Browser_Version',
        },
    ];

    const loginLogsSources = [];
    if (Loginlogs) {
        for (let i = 0; i < Loginlogs.length; ++i) {
            loginLogsSources.push({
                key: i,
                DateTime: Loginlogs[i].DateTime || <MdOutlineBlock className='red' />,
                IP: Loginlogs[i].IP || <MdOutlineBlock className='red' />,
                Device_Family: Loginlogs[i].Device_Family || <MdOutlineBlock className='red' />,
                Device_Brand: Loginlogs[i].Device_Brand || <MdOutlineBlock className='red' />,
                Device_Model: Loginlogs[i].Device_Model || <MdOutlineBlock className='red' />,
                Device_OS: Loginlogs[i].Device_OS || <MdOutlineBlock className='red' />,
                Operating_System_version: Loginlogs[i].Operating_System_version || <MdOutlineBlock className='red' />,
                Browser_Family: Loginlogs[i].Browser_Family || <MdOutlineBlock className='red' />,
                Browser_Version: Loginlogs[i].Browser_Version || <MdOutlineBlock className='red' />,
            })
        }
    }

    const LogsColumn = [
        {
            title: 'DATE',
            dataIndex: 'DateTime',
            key: 'DateTime',
        },
        {
            title: 'IP',
            dataIndex: 'IP',
            key: 'IP',
        },
        {
            title: 'ACTION',
            dataIndex: 'Action',
            key: 'Action',
        },
        {
            title: 'DESCRIPTION',
            dataIndex: 'Description',
            key: 'Description',
        },
    ];

    const LogsSources = [];
    if (logs) {
        for (let i = 0; i < logs.length; ++i) {
            LogsSources.push({
                key: i,
                DateTime: logs[i].DateTime || <MdOutlineBlock className='red' />,
                IP: logs[i].IP || <MdOutlineBlock className='red' />,
                Action: logs[i].Action || <MdOutlineBlock className='red' />,
                Description: logs[i].Description || <MdOutlineBlock className='red' />,
            })
        }
    }

    return (
        <>
            <Main>
                <Card>
                    <h1 className='text-25px uppercase theme-text text-center'>Login Logs</h1>
                    <div className='table-responsive'>
                        <Table size="small" dataSource={loginLogsSources.reverse()} columns={loginLogsColumn} />
                    </div>
                    <h1 className='text-25px uppercase theme-text text-center'>Logs</h1>
                    <div className='table-responsive'>
                        <Table size="small" dataSource={LogsSources.reverse()} columns={LogsColumn} />
                    </div>
                </Card>
            </Main>
        </>
    )
}

export default LoginLogs