import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import { RiDashboardFill } from "react-icons/ri";
import { IoIosCreate } from "react-icons/io";
import { AiFillFolderAdd } from "react-icons/ai";
import { BiSolidMessageAltDetail } from "react-icons/bi";
import { RiAccountCircleFill } from "react-icons/ri";


function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  return (
    <>
      <div className="brand">
        <p className="text-20px text-center">PAYMENT GATEWAY</p>
      </div>
      <hr />
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/dashboard">
            <span
              className="icon"
              style={{
                background: page === "dashboard" ? color : "",
              }}
            >
              {/* {dashboard} */}
              <RiDashboardFill className="text-20px" />
            </span>
            <span className="label">Dashboard</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="2">
          <NavLink to="/createApi">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {/* {tables} */}
              <IoIosCreate className="text-20px" />
            </span>
            <span className="label">Create Api</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/addWallet">
            <span
              className="icon"
              style={{
                background: page === "addWallet" ? color : "",
              }}
            >
              {/* {profile} */}
              <AiFillFolderAdd className="text-20px" />
            </span>
            <span className="label">Add Wallet</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="4">
          <NavLink to="/support">
            <span
              className="icon"
              style={{
                background: page === "support" ? color : "",
              }}
            >
              {/* {profile} */}
              <BiSolidMessageAltDetail className="text-20px" />
            </span>
            <span className="label">Support</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="5">
          <NavLink to="/Logs">
            <span
              className="icon"
              style={{
                background: page === "Logs" ? color : "",
              }}
            >
              <RiAccountCircleFill className="text-20px"/>
            </span>
            <span className="label">Logs</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/wallet">
            <span
              className="icon"
              style={{
                background: page === "wallet" ? color : "",
              }}
            >
              <RiAccountCircleFill className="text-20px"/>
            </span>
            <span className="label">Wallet</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="7">
          <NavLink to="/transaction">
            <span
              className="icon"
              style={{
                background: page === "transaction" ? color : "",
              }}
            >
              <RiAccountCircleFill className="text-20px"/>
            </span>
            <span className="label">Transaction</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item className="menu-item-header" key="7">
          Account Pages
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {/* {profile} */}
              <RiAccountCircleFill className="text-20px"/>
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item>

      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
          style={{
            background: color,
          }}
        >
          <span className="icon" style={{ color }}>
            <RiDashboardFill className="text-20px" />
          </span>
          <h6>Use This link for Apis!</h6>
          <p>https://uapi.advilite.com</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block white-btn" href="https://uapi.advilite.com/docs" target="_blank">
            DOCUMENTATION
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
