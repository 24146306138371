import React from "react";
import {
  Layout,
  Menu,
  Button,
  Card,
  Form,
  Input,
  Checkbox,
} from "antd";
import logo1 from "../assets/images/logos-facebook.svg";
import logo2 from "../assets/images/logo-apple.svg";
import logo3 from "../assets/images/Google__G__Logo.svg.png";

import { Link } from "react-router-dom";
import {
  DribbbleOutlined,
  TwitterOutlined,
  InstagramOutlined,
  GithubOutlined,
} from "@ant-design/icons";
import axios from "axios";

const { Header, Footer, Content } = Layout;

const SignUp = () => {

  const onFinish = (values) => {
    const postData = values
    console.log(postData)
    axios({
      method: "POST",
      headers: { "Content-Type": "application/json" },
      url: process.env.REACT_APP_API + "create_project",
      data: postData,
    })
      .then(async function (response) {
        const check = response.data
        console.log(check);
      })
      .catch((err) => {
        console.log(err)
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <>
      <div className="layout-default ant-layout layout-sign-up">
        <Content className="p-0">
          <div className="sign-up-header"></div>

          <Card
            className="card-signup header-solid h-full ant-card pt-0"
            title={<h5>Register With</h5>}
            bordered="false"
          >
            <div className="sign-up-gateways">
              <Button type="false">
                <img src={logo1} alt="logo 1" />
              </Button>
              <Button type="false">
                <img src={logo2} alt="logo 2" />
              </Button>
              <Button type="false">
                <img src={logo3} alt="logo 3" />
              </Button>
            </div>
            <p className="text-center my-25 font-semibold text-muted">Or</p>
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className="row-col"
            >
              <Form.Item
                name="project_name"
                rules={[
                  { required: true, message: "Please input your Project name!" },
                ]}
              >
                <Input placeholder="project name" />
              </Form.Item>
              <Form.Item
                name="project_description"
                rules={[
                  { required: true, message: "Please input your project description!" },
                ]}
              >
                <Input placeholder="project description" />
              </Form.Item>
              <Form.Item
                name="project_type"
                rules={[
                  { required: true, message: "Please input your project type!" },
                ]}
              >
                <Input placeholder="project type" />
              </Form.Item>
              <Form.Item
                name="project_url"
                rules={[
                  { required: true, message: "Please input your project url!" },
                ]}
              >
                <Input placeholder="project url" />
              </Form.Item>
              <Form.Item
                name="project_email"
                rules={[
                  { required: true, message: "Please input your project email!" },
                ]}
              >
                <Input placeholder="project email" />
              </Form.Item>
              <Form.Item
                name="project_password"
                rules={[
                  { required: true, message: "Please input your project password!" },
                ]}
              >
                <Input placeholder="project password" />
              </Form.Item>

              <Form.Item name="remember" valuePropName="checked">
                <Checkbox>
                  I agree the{" "}
                  <a href="#pablo" className="font-bold text-dark">
                    Terms and Conditions
                  </a>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <Button
                  style={{ width: "100%" }}
                  type="primary"
                  htmlType="submit"
                >
                  SIGN UP
                </Button>
              </Form.Item>
            </Form>
            <p className="font-semibold text-muted text-center">
              Already have an account?{" "}
              <Link to="/sign-in" className="font-bold text-dark">
                Sign In
              </Link>
            </p>
          </Card>
        </Content>
        <Footer>
          <Menu mode="horizontal" className="menu-nav-social">
            <Menu.Item>
              <Link to="#">{<DribbbleOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<TwitterOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<InstagramOutlined />}</Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="#">{<GithubOutlined />}</Link>
            </Menu.Item>
          </Menu>
          <p className="copyright">
            {" "}
            Copyright © 2024 by<a href="#pablo">Payment GateWay</a>.{" "}
          </p>
        </Footer>
      </div>
    </>
  );
};

export default SignUp;
