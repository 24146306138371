import React, { useEffect, useState } from 'react';
import { Tabs, Table, Button, Popover, Spin, Row, Col, Card } from 'antd';
import Main from '../components/layout/Main';
import axios from 'axios';
import { MdOutlineBlock } from "react-icons/md";

const { TabPane } = Tabs;

const Wallet = () => {
    const [chainsTabs, setChainsTabs] = useState([]);
    const [loadingTabs, setLoadingTabs] = useState(true);
    const [wallets, setWallets] = useState([]);
    const [loadingWallets, setLoadingWallets] = useState(false);
    const [selectedChain, setSelectedChain] = useState(null);
    const [popoverVisible, setPopoverVisible] = useState({});
    const [balanceData, setBalanceData] = useState({});

    const getChainTabs = () => {
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: `${process.env.REACT_APP_API}get_chains_tab`,
            data: postData
        })
            .then(response => {
                const data = response.data.Success;
                setChainsTabs(data);
                setLoadingTabs(false);
                // Load data for the first chain tab by default
                if (data.length > 0) {
                    setSelectedChain(data[0]);
                    getWalletsAlloted(data[0]);
                }
            })
            .catch(err => {
                console.log('API Error:', err);
                setLoadingTabs(false);
            });
    };

    const getWalletsAlloted = (chain) => {
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt, chain: chain };
        setLoadingWallets(true);
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: `${process.env.REACT_APP_API}get_wallets_alloted`,
            data: postData
        })
            .then(response => {
                const data = response.data.Success.map(item => ({
                    ...item,
                    Chain: item.Chain.replace("_", " ") // Replace underscore with space in Chain column
                }));
                setWallets(data);
                setLoadingWallets(false);
            })
            .catch(err => {
                console.log('API Error:', err);
                setLoadingWallets(false);
            });
    };

    useEffect(() => {
        getChainTabs();
    }, []);

    const handleTabChange = (key) => {
        const chain = chainsTabs[key];
        setSelectedChain(chain);
        getWalletsAlloted(chain);
    };

    const handlePopoverVisibleChange = (walletAddress, visible) => {
        setPopoverVisible(prevState => ({
            ...prevState,
            [walletAddress]: visible
        }));

        if (visible && !balanceData[walletAddress]) {
            const jwt = localStorage.getItem("jwt");
            const postData = { jwt: jwt, chain: selectedChain, wallet_address: walletAddress };
            setLoadingWallets(true);

            axios({
                method: "POST",
                headers: { "Content-Type": "application/json" },
                url: `${process.env.REACT_APP_API}check_wallet_balance`,
                data: postData
            })
                .then(response => {
                    const data = response.data.Success;
                    setBalanceData(prevState => ({
                        ...prevState,
                        [walletAddress]: data
                    }));
                    setLoadingWallets(false);
                    console.log(data)
                })
                .catch(err => {
                    console.log('API Error:', err);
                    setLoadingWallets(false);
                });
        }
    };

    const handleCheckBalance = (walletAddress) => {
        handlePopoverVisibleChange(walletAddress, true);
    };

    const renderBalanceContent = (data, walletAddress) => {
        if (!data || typeof data !== 'object') {
            return (
                <div>
                    {/* show msg */}
                </div>
            );
        }

        const { Balance, Postfix, USDT, USDT_Value } = data;

        return (
            <div>
                <div>
                    <strong>Balance: </strong>{Balance} {Postfix}
                </div>
                <div>
                    <strong>USDT: </strong>{USDT}
                </div>
                <div>
                    <strong>USDT Value: </strong>{USDT_Value}
                </div>
            </div>
        );
    };

    const WalletColumns = [
        {
            title: 'DATE',
            dataIndex: 'Date_Created',
            key: 'Date_Created',
        },
        {
            title: 'USERNAME',
            dataIndex: 'Username',
            key: 'Username',
        },
        {
            title: 'API NAME',
            dataIndex: 'Api_Name',
            key: 'Api_Name',
        },
        {
            title: 'CHAIN',
            dataIndex: 'Chain',
            key: 'Chain',
        },
        {
            title: 'WALLET ADDRESS',
            dataIndex: 'Wallet_Address',
            key: 'Wallet_Address',
        },
        {
            title: 'ACTION',
            dataIndex: 'action',
            render: (_, record) => (
                <Popover
                    content={renderBalanceContent(balanceData[record.Wallet_Address])}
                    title="Wallet Balance"
                    trigger="click"
                    visible={popoverVisible[record.Wallet_Address]}
                    onVisibleChange={(visible) => handlePopoverVisibleChange(record.Wallet_Address, visible)}
                >
                    <Button type="primary" onClick={() => handleCheckBalance(record.Wallet_Address)}>Check Balance</Button>
                </Popover>
            ),
        },
    ];

    return (
        <Main>
            <Row justify={'center'}>
                <Col xs={24} md={18}>
                    <Card>
                        {loadingTabs ? (
                            <Spin size="large" className='flex justify-center'/>
                        ) : (
                            <Tabs type='card' defaultActiveKey="0" onChange={handleTabChange}>
                                {chainsTabs.map((chain, index) => (
                                    <TabPane tab={chain.replace("_", " ")} key={index}>
                                        <div className='table-responsive'>
                                            <Table
                                                columns={WalletColumns}
                                                dataSource={wallets}
                                                rowKey="Wallet_Address"
                                            />
                                        </div>
                                    </TabPane>
                                ))}
                            </Tabs>
                        )}
                    </Card>
                </Col>
            </Row>
        </Main>
    );
};

export default Wallet;
