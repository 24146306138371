import React, { useEffect, useState } from 'react'
import Main from '../components/layout/Main'
import { Card, Table } from 'antd'
import axios from 'axios';

const Transaction = () => {

    const [AllPayment, setAllPayment] = useState([]);

    const GetAllPayment = () => {
        const jwt = localStorage.getItem("jwt");
        const postData = { jwt: jwt };
        axios({
            method: "POST",
            headers: { "Content-Type": "application/json" },
            url: process.env.REACT_APP_API + "get_all_payments",
            data: postData
        })
            .then(function (response) {
                const data = response.data.Success;
                console.log(data);
                setAllPayment(data);
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        GetAllPayment();
    }, []);

    const dataSource = [];
    if (AllPayment) {

        const sortedPayments = AllPayment.sort((a, b) => new Date(b.Date_Created) - new Date(a.Date_Created));

        const latestPayments = sortedPayments.reverse();

        // Map the latest payments to dataSource
        for (let i = 0; i < latestPayments.length; ++i) {
            dataSource.push({
                key: i,
                Date_Created: latestPayments[i].Date_Created,
                Project_ID: latestPayments[i].Project_ID,
                Transaction_ID: latestPayments[i].Transaction_ID,
                Username: latestPayments[i].Username,
                Chain: latestPayments[i].Chain.replace('_', " "),
                Transaction_Hash: latestPayments[i].Transaction_Hash,
                Wallet_Address: latestPayments[i].Wallet_Address,
                Status: latestPayments[i].Status,
                Ip: latestPayments[i].Ip,
            });
        }

        // Log dataSource for debugging
        console.log('Data Source:', dataSource);
    }

    const columns = [
        {
            title: 'DATE',
            dataIndex: 'Date_Created',
            key: 'Date_Created',
        },
        {
            title: 'PROJECT ID',
            dataIndex: 'Project_ID',
            key: 'Project_ID',
        },
        {
            title: 'TRANSACTION ID',
            dataIndex: 'Transaction_ID',
            key: 'Transaction_ID',
        },
        {
            title: 'USERNAME',
            dataIndex: 'Username',
            key: 'Username',
        },
        {
            title: 'CHAIN',
            dataIndex: 'Chain',
            key: 'Chain',
        },
        {
            title: 'TRANSACTION HASH',
            dataIndex: 'Transaction_Hash',
            key: 'Transaction_Hash',
        },
        {
            title: 'WALLET ADDRESS',
            dataIndex: 'Wallet_Address',
            key: 'Wallet_Address',
        },
        {
            title: 'IP',
            dataIndex: 'Ip',
            key: 'Ip',
        },
        {
            title: 'STATUS',
            dataIndex: 'Status',
            key: 'Status',
        },
    ];

    return (
        <Main>
            <div className="table-responsive">
                <Table size="small" dataSource={dataSource} columns={columns} />
            </div>
        </Main>
    )
}

export default Transaction